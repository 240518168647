"use client";
import Link from "next/link";

import { Image } from "@/components";
import { withCsr } from "@/utils";

import styles from "./Announcements.module.scss";
import { useImageLinks } from "./helpers";
import { ImageLinkList } from "../ImageLinkList";

export const Announcements = withCsr(function Announcements(): React.ReactNode {
  const { imageLinks } = useImageLinks();

  return (
    <div>
      <ImageLinkList images={imageLinks} />

      <div className="pd__bottom__s">
        <Link href="/products/deli/basepasta/umakaramazesoba" className={styles.link}>
          <Image
            src="https://asset.basefood.co.jp/images/bnr/bnr_mazesoba_W1080_H1080.jpg"
            alt="BASE PASTA 旨辛まぜそば 新発売"
            containerClassName={styles.imageContainer}
            className={styles.image}
          />
        </Link>
      </div>
    </div>
  );
});
